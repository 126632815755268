@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./roboto-slab-400-regular.woff2") format("woff2"),
    url("./roboto-slab-400-regular.woff2") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./roboto-400-regular.woff2") format("woff2"),
    url("./roboto-400-regular.woff2") format("woff");
}
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./roboto-slab-700.woff2") format("woff2"),
    url("./roboto-slab-700.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./roboto-500.woff2") format("woff2"),
    url("./roboto-500.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./roboto-700.woff2") format("woff2"),
    url("./roboto-700.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./roboto-900.woff2") format("woff2"),
    url("./roboto-900.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./inter-400-regular.woff2") format("woff2"),
    url("./inter-400-regular.woff") format("woff");
}
@font-face {
  font-family: 'Bangers';
  font-weight: 400;
  font-style: normal;
  src: url('./Bangers-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Creesper';
  font-weight: 400;
  font-style: normal;
  src: url('./Creepster-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Dancing';
  font-weight: 400;
  font-style: normal;
  src: url('./DancingScript-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Meriend';
  font-weight: 400;
  font-style: normal;
  src: url('./MeriendaOne-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Titanium';
  font-weight: 600;
  font-style: normal;
  src: url('./TitilliumWeb-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Inconsolata';
  font-weight: 600;
  font-style: normal;
  src: url('./Inconsolata-Bold.woff') format('woff');
}
@font-face {
  font-family: 'PressStart2P';
  font-weight: 400;
  font-style: normal;
  src: url('./PressStart2P-Regular.woff') format('woff');
}