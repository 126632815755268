@import url('./vendor/normalize.css');
@import url('./fonts/fonts.css');
body {

  max-width: 100VW;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  
}
#tsparticles{
  z-index: -1;
}
body::-webkit-scrollbar{
  width: 15px;
  background-color: black;
 
}
body::-webkit-scrollbar-track{
  border-radius: 100vw;
  background: linear-gradient(tomato, transparent);
  margin-block:15px;
}
body::-webkit-scrollbar-thumb{
  box-shadow: 1px 1px 5px 5px tomato;
  background-color: black;
  border: 2px solid tomato;
  border-radius: 100vw;
  
}
.main{
  opacity: 0;
  transition: opacity 3s ease-in;
}
.main_is-visible{
  display: flex;
  flex-direction: column;
  opacity: 1;
}

