.navigation{
    position: fixed;
    z-index: 4;
    display: flex;
    width: 100vW;
    flex-direction: row;
    justify-content: space-between;
    max-width: inherit;
    padding: 0px 3%;
    background-color: rgba(1,1,1,0.8);
    top:0;
}
.wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80px;
}
.navigation__list{
    display: flex;
    list-style: none;
    flex-direction: row;
    justify-content: space-between;
    width: 650px;
    align-items: center;
    
    margin-top: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
}
.navigation__burger-button{
    background-color: transparent;
    border: none;
    position: relative;
    background-repeat: no-repeat;
    padding: 0;
    height: 30px;
    width: 30px;
    align-self: center;
    cursor: pointer;
}
.navigation__burger-bar{
    height: 3px;
    width: 100%;
    background-color: white;
    display: block;
    margin-top: 5px;
    border-radius: 10px;
    transition:all .3s ease-in-out;
    
}
.navigation__burger-bar_type_top{
    transform: rotate(45deg);
    top: 50%;
    position: absolute;
    margin-top: 0px;
}
.navigation__burger-bar_type_middle{
    transform: translate(10px, 0px);
    opacity: 0;
}

.navigation__burger-button:hover{
    opacity: 0.5;
}
.navigation__burger-bar_type_bottom{
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    margin-top: 0px;
}


.navigation__link{
    background-color: transparent;
    color: white;
    border: none;
    font-size: 22px;
    padding: 0px;
    cursor: pointer;
    transition:color .3s ease-in-out;
    font-family:'Bangers',sans-serif;
    letter-spacing: 1px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    transition: color .3s ease, transform .3s ease;
    
    padding: 5px 10px;
    border-radius: 20px;
    
}
.navigation__link:hover{
    color: tomato;
    transform: rotate(15deg) scale(1.3);
    
   
}
.navigation__link_type_section-on-viewport{
    color: tomato;
    transform: rotate(15deg) scale(1.3);
}

.navigation__link_type_contact{
    background: linear-gradient(rgb(249, 43, 6),rgb(199 58 58));;
}
.navigation__link_type_contact:hover{
    color:black;
}

.navigation__logo{
    background-image: url('../../images/logo.png');
    object-fit: cover;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150%;
    cursor: pointer;
    transition: transform 1s ease;
  
}
.navigation__logo:hover{
    transform: rotate(360deg);
}

@media screen and (max-width:1000px) {
    .navigation__list{
        width: 520px;
    }
}
@media screen and (min-width:768px) {
    .navigation__burger-button{
        display: none;
    }
    
}
@media screen and (max-width:768px) {
    .navigation{
        /* width: 100%; */
       background-color: transparent;
    }
    .navigation__link{
        background-color: transparent;
    }
    .navigation_type_burger-menu{
        position: fixed;
        /* top: 0;
        bottom: 0;
        left: 0;
        right:0; */
        
        width: 100VW;
        background-color: rgba(1,1,1,0.8);
        flex-direction:column;
        align-items: center;
        justify-content: flex-start;
        
        
        

    }
    .navigation__list{
        height: 0px;
        opacity: 0;
        visibility: hidden;
        width: 0px;
        pointer-events: none;
        transition: all .5s;
    }
    
    .navigation__list_type_burger-menu{
        border-top:1px solid grey;
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        transition: all .5s;
        visibility: visible;
        opacity: 1;
    }
    .navigation__list_is-visible{
        display: flex;
        height: 100VH;
        pointer-events: all;
        
    }
    .navigation__logo_type_burger-menu{
        align-self: flex-start;
    }
    .navigation__item{
        margin-top: 15px;
    }
    .wrapper{
        width: 100%;
    }
    
}
