
  * {
    box-sizing: border-box;
  } 
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100VH;
    overflow: hidden;
  }
   
  .loader {
    width: 32px;
    height: 90px;
    display: block;
    margin: 20px auto;
    position: relative;
    border-radius: 50% 50% 0 0;
    border-bottom: 10px solid #ff3d00;
    background-color: #fff;
    background-image: radial-gradient(
        ellipse at center,
        #fff 34%,
        #ff3d00 35%,
        #ff3d00 54%,
        #fff 55%
      ),
      linear-gradient(#ff3d00 10px, transparent 0);
    background-size: 28px 28px;
    background-position: center 20px, center 2px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    animation: boost 3s linear alternate;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    
  }
  .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 64px;
    height: 44px;
    border-radius: 50%;
    box-shadow: 0px 15px #ff3d00 inset;
    top: 67px;
  }
  .loader::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 34px;
    height: 34px;
    top: 112%;
    background: radial-gradient(
      ellipse at center,
      #ffdf00 8%,
      rgba(249, 62, 0, 0.6) 24%,
      rgba(0, 0, 0, 0) 100%
    );
    border-radius: 50% 50% 0;
    background-repeat: no-repeat;
    background-position: -44px -44px;
    background-size: 100px 100px;
    box-shadow: 4px 4px 12px 0px rgba(255, 61, 0, 0.5);
    box-sizing: border-box;
    animation: animloader 5s linear infinite alternate;
    animation-fill-mode: forwards;
  }
  @keyframes boost {
    5% {
      transform: rotate(45deg);
    }
    100% {
      transform: translate(1000px, -1000px) scale(3);

    }
  }
  @media screen and (max-width:550px) {
    @keyframes boost {
      5% {
        transform: rotate(30deg);
      }
      100%{
        transform: translate(500px, -1000px) scale(3);
      }
    }
  }
  
  @keyframes animloaderBack {
    0%,
    30%,
    70% {
      transform: translateY(0px);
    }
    20%,
    40%,
    100% {
      transform: translateY(-5px);
    }
  }
  
  @keyframes animloader {
    0% {
      box-shadow: 4px 4px 12px 2px rgba(255, 61, 0, 0.75);
      width: 34px;
      height: 34px;
      background-position: -44px -44px;
      background-size: 100px 100px;
    }
    100% {
      box-shadow: 2px 2px 8px 0px rgba(255, 61, 0, 0.5);
      width: 30px;
      height: 28px;
      background-position: -36px -36px;
      background-size: 80px 80px;
    }
  }
  .loader_type_line {
    display: block;
    position: relative;
    height: 25px;
    width: 80%;
    border: 2px solid #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 30px;
  }
  
  .loader_type_line::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: 'Inter',sans-serif;
    font-weight: 700;
    color: red;
    animation: percentage 5s linear;
    z-index: 1;
    animation-fill-mode: forwards;
    
  }
  .loader_type_line:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: linear-gradient(135deg, rgba(23,7,0,1) 0%, rgba(255,92,31,1) 15%, rgba(17,9,6,1) 30%, rgba(249,73,5,1) 44%, rgba(24,14,10,1) 57%, rgba(249,73,5,1) 69%, rgba(29,11,4,1) 80%, rgba(218,66,8,1) 91%, rgba(60,18,2,1) 100%);
    animation: 5s prog linear;
    animation-fill-mode: forwards;
    color: red;
  }
  
  @keyframes prog {
    to {
      width: 100%;
    }
  }
  @keyframes percentage {
    0% {
      content: "0%";
    }
    5% {
      content: "5%";
    }
    10% {
      content: "10%";
    }
    20% {
      content: "20%";
    }
    30% {
      content: "30%";
    }
    40% {
      content: "40%";
    }
    50% {
      content: "50%";
    }
    55% {
      color: white;
    }
    60% {
      content: "60%";
    }
    70% {
      content: "70%";
    }
    80% {
      content: "80%";
    }
    90% {
      content: "90%";
    }
    95% {
      content: "95%";
    }
    96% {
      content: "96%";
    }
    97% {
      content: "97%";
    }
    98% {
      content: "98%";
    }
    99% {
      content: "99%";
    }
    100% {
      content: "100%";
      color: white;
    }
  }
  