.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(249, 120, 98, 0.49653364763874297) 0%,
    rgba(0, 0, 0, 0.19681375968356096) 10%,
    rgba(0, 0, 0, 0.19961488013174022) 90%,
    rgba(249, 120, 98, 0.5049370089832808) 100%
  );

  transition: opacity 0.5s ease, transform 0.5s ease;
}
.about_is-visible {
  opacity: 1;
}

.circle {
  position: relative;
  width: 400px;
  height: 400px;
  perspective: 1000px; /* Set the perspective for 3D transform */
}

.circle-front,
.circle-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back face of the element */
  transition: transform 0.8s ease-in-out; /* Add a transition effect */
}

.circle-front {
  z-index: 2; /* Set the front element on top */
}

.circle-back {
  transform: rotateY(180deg); /* Rotate the back element by 180 degrees */
}

.circle:hover .circle-front {
  transform: rotateY(
    -180deg
  ); /* Rotate the front element by -180 degrees on hover */
}

.circle:hover .circle-back {
  transform: rotateY(0); /* Rotate the back element by 0 degrees on hover */
}

.animation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  width: 100%;
  z-index: 1;
}
.animation-wrapper_animatied {
  animation: showContent 0.5s linear;
  animation-fill-mode: forwards;
}
@keyframes showContent {
  from {
    opacity: 0;
    transform: scale(0.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.about__header {
  margin: 130px 0px 0px 0px;
  font-family: "Bangers", sans-serif;
  font-weight: 700;
  font-size: 90px;
  letter-spacing: 2px;
  background: -webkit-linear-gradient(rgb(249, 43, 6), rgba(181, 168, 168, 1));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 10px;
}
.about__content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
  max-width: 1300px;
  margin-bottom: 130px;
}
.about__subtitle {
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 19px;
  line-height: 140%;
  color: white;
  font-family: "Meriend", sans-serif;
  font-weight: 400;
  background: rgba(1, 1, 1, 0.8);
  border-radius: 30px;
  max-width: 800px;
  line-height: 150%;
}
.about__subtitle:nth-child(1) {
  margin-top: 0px;
}
.about__link {
  text-decoration: none;
  color: tomato;
  transition: opacity 0.5s ease-in-out;
}
.about__link:hover {
  opacity: 0.5;
}

.about__img {
  border-radius: 50%;
  box-shadow: 0px 0px 30px 0px tomato;
  padding: 4px;
  object-fit: contain;
  /* height: 420px; */
  padding: 20px;
  margin-right: 30px;
  margin-top: 20px;
}
@media screen and (max-width: 1450px) {
  .about__subtitle {
    padding: 15px;
    font-size: 19px;
    max-width: 700px;
  }
  .about__content-wrapper {
    width: 90%;
  }
}
@media screen and (max-width: 1200px) {
  .circle {
    height: 350px;
  }
  .about__header {
    font-size: 90px;
  }
  .about__subtitle {
    padding: 15px;
    font-size: 17px;
  }
  .about__content-wrapper {
    width: 90%;
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 1000px) {
  .circle {
    height: 300px;
    align-self: center;
    margin-top: 0px;
  }

  .about__subtitle {
    font-size: 16px;
  }
  .about__content-wrapper {
    margin-top: 40px;
  }
}
@media screen and (max-width: 900px) {
  .about__header {
    font-size: 80px;
  }
  .about__subtitle {
    font-size: 14px;
  }
  .about__content-wrapper {
    margin-top: 30px;
  }
}
@media screen and (max-width: 850px) {
  .about__content-wrapper {
    flex-direction: column-reverse;
    margin-top: 0px;
  }
  .about__subtitle {
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    max-width: 800px;
  }
  .circle {
    height: 250px;
    width: 250px;
    margin-top: 60px;
    margin-right: 0px;
  }
  .about__header {
    margin-top: 100px;
  }
}
@media screen and (max-width: 660px) {
  .about__content-wrapper {
    flex-direction: column-reverse;
    margin-top: 0px;
  }
  .about__subtitle {
    padding: 10px;
    margin-top: 10px;
  }
  .circle {
    /* height: 220px; */
    margin-top: 50px;
  }
  .about__header {
    font-size: 70px;
  }
}
@media screen and (max-width: 515px) {
  .about__content-wrapper {
    margin-top: 10px;
  }
  .about__subtitle {
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  .about__content-wrapper {
    margin-top: 10px;
    width: 95%;
  }
  .circle {
    /* height: 220px; */
    margin-top: 60px;
  }
  .about__subtitle {
    padding: 0px;

    font-size: 11px;
  }
}
