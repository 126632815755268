
.ironman{
    position: fixed;
    background-image: url('../../images/ironman.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 69px;
    height: 110px;
    max-width: 69px;
    max-height: 110px;
    z-index: 2;
    /* margin: 0px auto; */
    bottom: 7%;
    cursor: pointer;
    left: 2%;
    transform: translate(-1000px, 0);
    transition: all 2s ease;
    animation: 2s flying linear infinite , moving 2s linear 1 ;
    animation-delay: 9s;
    
    
}
@keyframes moving {
    from {
        transform:  translate(-1000px,0);
       
        
    }
    to {
        transform:  translate(0px , 0px);
        
      
    } 
}
@keyframes flying {
    0%{
        transform: translate(0,0);
    }
    50%{
        transform: translate(0,20px);
    }
    100%{
        transform: translate(0,0);
    }
}

 .ironman__subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    width: 210px;
    height: 40px;
    border-radius: 50%;
    left:50px;
    top: -17%;
    color: white;
    background-color: tomato;
    box-shadow: 4px 4px 12px 0px rgba(255, 61, 0, 0.5);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    opacity: 1;
     pointer-events: all;
     visibility: visible;
   
 }
 
 .ironman_show-contact{
     left: calc(100% - 128px);
     
 }
 .ironman__subtitle_type_show-contact{
     left:-200px;
 }
 .ironman__button_type_reset-is-diabled{
     pointer-events: none;
     cursor: none;
     opacity: 0.3;
     background-color: grey;
 }
 .ironman_close{
     left: 2%;
 }
 .ironman__subtitle_not-visble{
     opacity: 0;
     pointer-events: none;
     visibility: hidden;
 }
 
 .ironman__link{
     width: 100%;
     height: 100%;
     position: absolute;
 }
 .ironman__button{
    border: none;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
     background-position: center;
     background-size: 100%;
    background-color: tomato;
    border-radius: 50%;
    position: absolute;
    border-radius: 50% 50% 0;
    box-shadow: 7px 7px 12px 0px rgba(255, 61, 0, 0.5);
    cursor: pointer;
 }
 .ironman__button_type_close{
     bottom: -25px;
     left: 5px;
     background-image: url('../../images/icons8-close-16.png');
     
 }
 .ironman__button_type_reset{
    bottom: -25px;
    right: 8px;
    background-image: url('../../images/icons8-refresh-16.png');
    
}
@media screen and (max-width:1000px) {
    .ironman__subtitle{
        left:63px;
        top:50%
    }
    .ironman__subtitle_type_show-contact{
        left: -207px;
    }
}
@media screen and (max-width:550px) {
    .ironman{
        width: 54px;
        height: 90px;
    }
    .ironman__button_type_close{
        left: 0px;
    }
    .ironman__button_type_reset{
        right: 2px;
    }
    .ironman__subtitle{
        width: 170px;
        height: 35px;
        font-size: 10px;
        left: 53px;
    }
    .ironman__subtitle_type_show-contact{
        left: -166px;
    }
    .ironman_show-contact{
        left:calc(100% - 110px)
    }
    .ironman_close{
        left:2%
    }
}
