.tech {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(249, 120, 98, 0.49653364763874297) 0%,
    rgba(0, 0, 0, 0.19681375968356096) 10%,
    rgba(0, 0, 0, 0.19961488013174022) 90%,
    rgba(249, 120, 98, 0.5049370089832808) 100%
  );
}
.tech__title {
  font-size: 90px;
  margin-top: 150px;
  padding: 10px;
  font-family: "Bangers", sans-serif;
  background: -webkit-linear-gradient(rgb(249, 43, 6), rgba(181, 168, 168, 1));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0px;
}
.tech__link {
  width: 100%;
  display: block;
  height: 100%;
}
.tech__item {
  /* background-image:url('../../images/html.png') ;
    width: 128px;
    height: 128px; */
  background-color: black;
  border-radius: 25%;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  box-shadow: 1px 1px 10px 10px rgba(255, 99, 71, 0.5);
}
.tech__item:hover {
  transform: scale(1.3) rotate(720deg);
}
.tech__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 0px;
  grid-gap: 50px;
  list-style: none;
  margin: 100px 0px 250px 0px;
}
.tech__item_type_html {
  background-image: url("../../images/html.png");
  background-color: white;
}
.tech__item_type_css {
  background-image: url("../../images/css.png");
  background-color: white;
}
.tech__item_type_js {
  background-image: url("../../images/js.png");
}
.tech__item_type_react {
  background-image: url("../../images/react.png");
}
.tech__item_type_redux {
  background-image: url("../../images/redux.png");
}
.tech__item_type_node {
  background-image: url("../../images/node.png");
}
.tech__item_type_express {
  background-image: url("../../images/express.png");
  background-color: white;
}
.tech__item_type_mongo {
  background-image: url("../../images/mongo.png");
}
.tech__item_type_figma {
  background-image: url("../../images/figma.png");
}
.tech__item_type_github {
  background-image: url("../../images/gitHub.png");
}

@media screen and (max-width: 1000px) {
  .tech__item {
    background-size: 100%;
    height: 100px;
    width: 100px;
  }
}
@media screen and (max-width: 800px) {
  .tech__list {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
    grid-gap: 30px;
  }
  .tech__item {
    height: 85px;
    width: 85px;
  }
  .tech__title {
    font-size: 80px;
    margin-top: 70px;
  }
}
@media screen and (max-width: 660px) {
  .tech__title {
    font-size: 70px;
  }
  .tech__list {
    margin-top: 30px;
  }
}
@media screen and (max-width: 400px) {
  .tech__title {
    font-size: 55px;
  }

  .tech__item {
    height: 80px;
    width: 80px;
    box-shadow: 0.5px 0.5px 5px 5px rgba(255, 99, 71, 0.5);
  }
}
