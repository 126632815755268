.header {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(249, 120, 98, 0.49653364763874297) 0%,
    rgba(0, 0, 0, 0.19681375968356096) 10%,
    rgba(0, 0, 0, 0.19961488013174022) 90%,
    rgba(249, 120, 98, 0.5049370089832808) 100%
  );
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* opacity: 0.7; */
  z-index: 1;
}
/* .header::after{
    content: '';
    position: absolute;
    width: 100%;
    height:100VH;
    background: black;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    z-index: 0;

} */
.header__content {
  z-index: 2;

  display: flex;
  flex-direction: column;
}
.header__content-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  max-width: 1600px;
  width: 95%;
  justify-content: space-between;
}

.header__title {
  overflow: hidden;
  margin: 0;
  color: black;
  white-space: nowrap;
  background: -webkit-linear-gradient(rgb(249, 43, 6), rgba(181, 168, 168, 1));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  font-family: "Bangers", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
}
.header__title:nth-child(1) {
  border-right: 4px solid orange;
  animation: type 3s steps(13), flashing 0.75s step-start infinite;
  animation-fill-mode: forwards;
  font-size: 60px;
}
.header__title:nth-child(2) {
  border-right: 4px solid orange;
  opacity: 0;
  animation: type2 3s steps(13), flashing 0.75s step-start infinite;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  margin-top: 30px;
  font-size: 100px;
}
.header__title:nth-child(3) {
  border-right: 4px solid orange;
  opacity: 0;
  animation: type3 3s steps(25, end), flashing 0.75s step-start infinite;
  animation-delay: 6s;
  animation-fill-mode: forwards;
  margin-top: 30px;
  font-size: 60px;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
    width: 55%;
  }
}
@keyframes type2 {
  0% {
    width: 0;
    opacity: 1;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }

  100% {
    opacity: 1;
    border: none;
    width: 100%;
  }
}
@keyframes type3 {
  0% {
    width: 0;
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    width: 100%;
    border-right: 0.15em solid orange;
  }
}

@keyframes flashing {
  0% {
    border-color: orange;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: orange;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
    transform: rotate(0deg) scale(0.1);
  }
  to {
    opacity: 1;
    transform: rotate(720deg) scale(1);
  }
}
.header__subtitle {
  z-index: 2;
  color: white;
  font-family: "Dancing", sans-serif;
  font-size: 60px;
  width: 652px;
  line-height: 120%;
  text-shadow: 4px 4px black;
  opacity: 0;
  animation: opacity 3s linear;
  animation-delay: 9s;
  animation-fill-mode: forwards;

  align-self: center;
}
.header__links {
  position: fixed;
  right: 15px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  z-index: 100;
}
.header__link_type_email {
  background-image: url("../../images/gmailos.png");
}
.header__link_type_cv {
  background-image: url("../../images/curriculum-vitae.png");
}
.header__link_type_github {
  background-image: url("../../images/githubos.png");
}
.header__link_type_linkedin {
  background-image: url("../../images/linkedin.png");
}
.header__link_type_whatsapp {
  background-image: url("../../images/whatsapp.png");
}
.header__link {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-position: center;
  background-position: center;
  background-size: 100%;
  transition: transform 0.5s;
  opacity: 0.7;
}
.header__link:hover {
  transform: scale(1.3);
  opacity: 1;
}
.header__music-btn {
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 10;
  right: 1%;
  top: 100px;
  border-radius: 50%;
  border: none;
  background-image: url("../../images/mute.png");
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: tomato;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.5s ease;
}
.header__music-btn_is-playing {
  background-image: url("../../images/volume.png");
  opacity: 0.8;
}
.header__music-btn:hover {
  opacity: 1;
}

@media screen and (max-width: 1400px) {
  .header__subtitle {
    width: 550px;
    font-size: 50px;
  }
  .header__title:nth-child(2) {
    margin-top: 20px;
    font-size: 80px;
  }
  .header__title:nth-child(3) {
    margin-top: 20px;
    font-size: 50px;
  }
  .header__title:nth-child(1) {
    font-size: 50px;
  }
}
@media screen and (max-width: 1150px) {
  .header__content-wrapper {
    flex-direction: column;
    height: 430px;
  }
  .header__content {
    width: 490px;
  }
  .header__subtitle {
    align-self: flex-end;
  }
}

@media screen and (max-width: 900px) {
  .header__subtitle {
    font-size: 40px;
    width: 437px;
  }
}
@media screen and (max-width: 500px) {
  .header__title:nth-child(2) {
    margin-top: 20px;
    font-size: 56px;
  }
  .header__title:nth-child(3) {
    margin-top: 20px;
    font-size: 30px;
  }
  .header__title:nth-child(1) {
    font-size: 30px;
  }
  .header__content-wrapper {
    height: 323px;
  }

  .header__subtitle {
    font-size: 27px;
    width: 294px;
  }
  .header__content {
    width: 302px;
  }
  .header__link {
    width: 23px;
    height: 23px;
  }
  .header__links {
    height: 160px;
  }
}
@media screen and (max-width: 320px) {
  .header__content-wrapper {
    align-items: center;
  }
  .header__subtitle {
    align-self: center;
  }
}
