.contact {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(249, 120, 98, 1) 0%,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 100%,
    rgba(249, 120, 98, 0.5049370089832808) 100%
  );
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
}
.contact__title {
  background: -webkit-linear-gradient(rgb(249, 43, 6), rgba(181, 168, 168, 1));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
  margin-top: 100px;
  font-size: 90px;
  z-index: 1;
  font-family: "Bangers", sans-serif;
  letter-spacing: 2px;
  padding: 10px;
  margin-bottom: 0px;
}
.contact__subtitle {
  z-index: 1;
  color: white;
  border-radius: 10px;
  max-width: 700px;
  padding: 20px;
  font-size: 20px;
  font-family: "Meriend", sans-serif;
  text-align: center;
  line-height: 150%;
  position: relative;
  border-radius: 20px;
}
.contact__subtitle::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.contact__form {
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px;
  width: 500px;
  margin-bottom: 50px;
}
.contact__form::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    151deg,
    rgba(249, 140, 98, 1) 0%,
    rgba(71, 40, 28, 1) 31%,
    rgba(71, 40, 28, 1) 65%,
    rgba(249, 140, 98, 1) 100%
  );
  opacity: 0.5;
  z-index: -1;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
.contact__label {
  color: white;
  margin-top: 20px;
  color: white;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 400;
}
.contact__form-title {
  color: white;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 20px;
}

.contact__input {
  margin-top: 10px;
  border-radius: 10px;
  border-style: none;
  padding: 10px;
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.contact__button {
  margin-top: 60px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  height: 40px;
  background: linear-gradient(
    151deg,
    rgba(249, 140, 98, 1) 0%,
    rgba(71, 40, 28, 1) 31%,
    rgba(71, 40, 28, 1) 65%,
    rgba(249, 140, 98, 1) 100%
  );
  color: white;
  font-family: "Roboto Slab", sans-serif;
  font-size: 17px;
  font-weight: 700;
  height: 50px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}
.contact__button:disabled {
  opacity: 0.4;
  pointer-events: none;
}
.contact__button:hover {
  opacity: 1;
}
.contact__button_is-blinking {
  text-decoration: blink;
  animation-name: blinker;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.contact__form-error {
  color: tomato;
  margin-top: 5px;
  font-size: 15px;
  font-family: "Roboto Slab", sans-serif;
  font-weight: 700;
}

.contact__input::placeholder {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.4;
}
@media screen and (max-width: 1000px) {
  .contact__title {
    font-size: 80px;
  }
  .contact__subtitle {
    font-size: 17px;
    max-width: 600px;
    margin-top: 0px;
  }
}
@media screen and (max-width: 770px) {
  .contact__form {
    width: 450px;
    padding: 15px;
  }
}
@media screen and (max-width: 550px) {
  .contact__title {
    font-size: 65px;
  }
  .contact__subtitle {
    font-size: 14px;
    line-height: 166%;
    max-width: 320px;
    padding: 16px;
  }
  .contact__form {
    width: 90%;
  }
  .contact__form-error {
    font-size: 13px;
  }
}
@media screen and (max-width: 400px) {
  .contact__form-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .contact__button {
    margin-top: 45px;
  }

  .contact__form {
    width: 95%;
    padding: 10px;
  }
  .contact__label {
    font-size: 15px;
  }
  .contact__input {
    padding: 6px;
  }
  .contact__form-error {
    font-size: 11px;
  }
}
